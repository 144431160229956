import { useEffect } from "react";
import { useSearchParams } from "react-router-dom";

import scrollToTop from "../../helpers/scroll.to.top";

import { useDispatch, useSelector } from "react-redux";
import {
  getCheckInList,
  setData,
  setHasMore,
} from "../../redux/reducer/checkInReducer";

import Login from "../Login";
import TopUp from "../Home/modals/top.up";
import Tnc from "../SpinToWin/modals/tnc";
import CheckInInfo from "./components/check.in.info";
import PrizeList from "../SpinToWin/modals/prize.list";
import CheckInStamp from "./components/check.in.stamp";
import PromoDetails from "../../components/common/promo.details";

export default function CheckIn() {
  const dispatch = useDispatch();

  const [searchParams, setSearchParams] = useSearchParams();

  const { merchantId } = useSelector((state) => state.common);
  const { checkInPrizeList, checkInStampTerms, data } = useSelector(
    (state) => state.check_in
  );
  const {
    isOpenTncModal,
    isOpenTopUpModal,
    isOpenLoginModal,
    isOpenPrizeModal,
    isOpenPromotionDetailsModal,
  } = useSelector((state) => state.modal);

  useEffect(() => {
    scrollToTop();
  }, []);

  useEffect(() => {
    if (merchantId) {
      dispatch(
        getCheckInList({
          merchant_id: merchantId,
          start: 0,
          length: 10,
        })
      )
        .unwrap()
        .then((res) => {
          dispatch(setData(res.data.user_check_in_history));

          if (data.length < res.data.history_total_records) {
            dispatch(setHasMore(true));
          }
        })
        .catch((ex) => {});
    }
  }, [merchantId]);

  return (
    <>
      <article className="check-in-container">
        <CheckInStamp />
        <CheckInInfo />
      </article>
      {isOpenLoginModal && <Login type="full" />}
      {isOpenPromotionDetailsModal && <PromoDetails />}
      {isOpenTopUpModal && <TopUp />}
      {isOpenTncModal && <Tnc tnc={checkInStampTerms} />}
      {isOpenPrizeModal && <PrizeList prizeList={checkInPrizeList} />}
    </>
  );
}
