import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import scrollToTop from "../../helpers/scroll.to.top";
import { useToast } from "../../components/common/toast.provider";

import { useDispatch, useSelector } from "react-redux";
import {
  getSpinHistory,
  getWheelList,
  setData,
  setHasMore,
} from "../../redux/reducer/spinToWinReducer";

import Login from "../Login";
import Tnc from "./modals/tnc";
import TopUp from "../Home/modals/top.up";
import PrizeList from "./modals/prize.list";
import SpinInfo from "./components/spin.info";
import SpinWheel from "./components/spin.wheel";
import WalletHistory from "../Home/modals/wallet.history";
import PromoDetails from "../../components/common/promo.details";
import PrizeDetails from "../../components/common/prize.details";

export default function SpinToWin() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const toast = useToast();
  const { store } = useParams();

  const { merchantId, login, token } = useSelector((state) => state.common);
  const { wheelList, spinWheelTerms, start, length, data } = useSelector(
    (state) => state.spin_to_win
  );
  const {
    isOpenPrizeDetailsModal,
    isOpenPromotionDetailsModal,
    isOpenTopUpModal,
    isOpenHistoryModal,
    isOpenPrizeModal,
    isOpenTncModal,
    isOpenLoginModal,
  } = useSelector((state) => state.modal);

  useEffect(() => {
    scrollToTop();
  }, []);

  useEffect(() => {
    if (merchantId) {
      dispatch(
        getWheelList({
          merchant_id: merchantId,
        })
      );

      if ((login === "partial" || login === "full") && token) {
        dispatch(
          getSpinHistory({
            merchant_id: merchantId,
            has_reward: "",
            startDate: "",
            endDate: "",
            start: start,
            search: store,
            length: length,
          })
        )
          .unwrap()
          .then((res) => {
            dispatch(setData(res.data.aaData));
            if (data.length < res.data.iTotalRecords) {
              dispatch(setHasMore(true));
            }
          })
          .catch((ex) => {});
      }
    }
  }, [merchantId, login, token]);

  return (
    <>
      <article className="spin-to-win-container">
        <SpinWheel />
        <SpinInfo />
      </article>

      {isOpenHistoryModal && <WalletHistory />}
      {isOpenPrizeDetailsModal && <PrizeDetails />}
      {isOpenPromotionDetailsModal && <PromoDetails />}
      {isOpenTopUpModal && <TopUp />}
      {isOpenPrizeModal && <PrizeList prizeList={wheelList} />}
      {isOpenLoginModal && <Login type="full" />}
      {isOpenTncModal && <Tnc tnc={spinWheelTerms} />}
    </>
  );
}
